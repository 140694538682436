<template>
  <div
    class="
      border border-color-vio
      block
      rounded
      shadow
      p-3
      mb-5
      bg-white
      rounded
    "
  >
    <div v-if="has_notification">
      <h4 class="text-center content-blocks-title">
        <strong>{{ Block_Title }}</strong>
      </h4>
      <hr />
      <div
          v-if="nb_documents_juridique > 0"
          class="
          border border-color-notification
          block
          shadow
          py-3
          px-2
          mb-3
          bg-white
          text-start
        "
      >
        <span class="notification-status-dot">{{ nb_documents_juridique }}</span>
        <span class="notification-new-doc">
          <b>{{ Notification_Title }}</b>
        </span>
        <p class="block-content-font">{{ Notification_LettreAssu }}</p>
        <div class="text-center">
          <button
              type="button"
              class="button-warning-cus to-validate-with-frost"
              @click="$router.push({ path: TRACK_FOLDER_PATH })"
          >
            {{ Block_Access_Button }}
            <div class="arrow-white" />
          </button>
        </div>
      </div>
      
      <div
        v-if="nb_documents_signing && showDocumentsSigning"
        class="
          border border-color-notification
          block
          shadow
          py-3
          px-2
          mb-3
          bg-white
          text-start
        "
      >
        <span class="notification-status-dot-red">{{ nb_documents_signing }}</span>
        <span class="notification-new-doc">
          <b>{{ Notification_Signing_Title }}</b>
        </span>
        <p class="block-content-font">{{ Notification_Signing_Description }}</p>
        <div class="text-center">
          <button
            type="button"
            class="button-warning-cus to-validate-with-frost"
            @click="$router.push({ path: TRACK_FOLDER_PATH })"
          >
            {{ Block_Access_Button }}
            <div class="arrow-white" />
          </button>
        </div>
      </div>

      <div
          v-if="nb_documents_validate > 0"
          class="
          border border-color-notification
          block
          shadow
          py-3
          px-2
          mb-3
          bg-white
          text-start
        "
      >
        <span class="notification-status-dot">{{ nb_documents_validate }}</span>
        <span class="notification-new-doc">
          <b>{{ Notification_Title }}</b>
        </span>
        <p class="block-content-font">{{ Notification_Validate_Description }}</p>
        <div class="text-center">
          <button
              type="button"
              class="button-warning-cus to-validate-with-frost"
              @click="$router.push({ path: TRACK_FOLDER_PATH })"
          >
            {{ Block_Access_Button }}
            <div class="arrow-white" />
          </button>
        </div>
      </div>
    </div>
    <!--
    <div
        v-if="nb_documents_waiting"
        class="
          border border-color-notification
          block
          shadow
          py-3
          px-2
          mb-3
          bg-white
          text-start
        "
    >
      <span class="notification-status-dot">{{ nb_documents_waiting }}</span>
      <span class="notification-new-doc">
          <b>{{ Notification_Title }}</b>
        </span>
      <p class="text-center block-content-font">{{ Notification_Description }}</p>
      <div class="text-center">
        <button
            type="button"
            class="button-warning-cus to-validate-with-frost"
            @click="$router.push({ path: CONSTITUTE_FOLDER_PATH })"
        >
          {{ Block_Access_Button }}
          <div class="arrow" />
        </button>
      </div>
    </div>
    -->
    <div v-else>
        <h4 class=" text-center content-blocks-title"><strong>{{Block_Title_No_Notif}}</strong></h4>
        <hr />
        <img :src="suivreDossierImgSrc" alt="icon dossier"/>
        <p class="text-center block-content-font text-start">{{No_Notification_Description}}</p>
        <div class="text-center">
          <button type="button" class="button-secondary-cus to-validate-with-frost" @click="$router.push({ path:TRACK_FOLDER_PATH })">{{Block_Access_Button}}<div class="arrow-orange"/>
          </button>
        </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import events from "../../constants/events";
import emitter from "../../events/emitter";
import getNotifications from "../../mixins/getNotifications";
import openDocumentSigingLink from "../../mixins/openDocumentSigingLink";
import {CONSTITUTE_FOLDER_PATH, TRACK_FOLDER_PATH} from "../../router/pathes";
import document_signing_status from "../../constants/document_signing_status";

export default {
  setup() {
    const store = useStore();
    const computedGetDocuments = computed(() => store.getters[`document/getDocuments`]);
    const computedGetLegalDocuments = computed(() => store.getters[`document/getLegalDocuments`]);
    const computedGetDocumentsSigning = computed(() => store.getters[`document/getDocumentsSigning`]);

    return {
      computedGetDocuments,
      computedGetLegalDocuments,
      computedGetDocumentsSigning,
    };
  },
  props: {
    showDocumentsSigning: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      Block_Access_Button: "Accéder",
      Block_Title: "NOTIFICATIONS",
      documentsSigning: [],
      has_notification: false,
      nb_documents_waiting: 0,
      nb_documents_signing: 0,
      nb_documents_validate: 0,
      nb_documents_juridique: 0,
      Notification_Title: "Nouveaux documents",
      Notification_Date: "",
      Notification_Description:
        "De nouveaux documents sont nécessaires à la constitution de votre dossier.",
      Notification_LettreAssu: "Nous vous invitons à lire la lettre d'informations et à valider votre convention d'intermédiation avant de constituer votre dossier.",
      Notification_Empty: "Aucune notification",
      Notification_Signing_Title: "Nouveaux documents à signer",
      Notification_Signing_Description:
        "Nous vous invitons à prendre connaissance de nouveaux documents indispensables à l'avancement de votre dossier.",
      Notification_Validate_Description: "Nous vous invitons à prendre connaissance de nouveaux documents.",
      Notification_Signing_Button: "Signer",
      Notification_Signing_Status_Error: "Merci de contacter votre commercial.",
      No_Notification_Description: "Accéder à vos documents contractuels et à l'avancement de votre dossier.",
      Block_Title_No_Notif: "Mon dossier",
      suivreDossierImgSrc: require('@/assets/images/suivre-dossier-bl.svg'),
    };
  },
  methods: {
    /**
     * @param {Object} documentSigning
     * @returns {boolean}
     */
    canSign(documentSigning) {
      return (
        documentSigning.status === document_signing_status.PROGRESS &&
        documentSigning.transactionId
      );
    },
    getData() {
      this.getNotifications().then(this.whenReceiveNotifications);
    },
    /**
     *
     * @param {*} event
     * @param {Object} documentSigning
     */
    onClickSign(event, documentSigning) {
      const target = event.currentTarget;
      this.openDocumentSigingLink(documentSigning, target);
    },
    /**
     * @param {Object} notifications
     * @param {Array} notifications.documentsSigning
     * @param {int} notifications.nbDocumentsWaiting
     * @param {int} notifications.nbDocumentsToValidate
     * @param {int} notifications.nbDocumentsSigning
     * @param {int} notifications.nbDocumentsJuridique
     */
    whenReceiveNotifications({
      documentsSigning,
      nbDocumentsWaiting,
      nbDocumentsToValidate,
      nbDocumentsSigning,
      nbDocumentsJuridique,
    }) {
      this.has_notification = (this.showDocumentsSigning && (nbDocumentsToValidate || nbDocumentsSigning || nbDocumentsJuridique));
      this.documentsSigning = documentsSigning;
      this.nb_documents_waiting = nbDocumentsWaiting;
      this.nb_documents_validate = nbDocumentsToValidate;
      this.nb_documents_signing = nbDocumentsSigning;
      this.nb_documents_juridique = nbDocumentsJuridique;
      // emit event
      emitter.emit(events.home.notificationsDone);
    },
  },
  mixins: [openDocumentSigingLink, getNotifications],
  created() {
    this.TRACK_FOLDER_PATH = TRACK_FOLDER_PATH;
    this.CONSTITUTE_FOLDER_PATH = CONSTITUTE_FOLDER_PATH;
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/components/span-filename.scss";
.notification-status-dot-red + .notification-new-doc {
  margin-left: 0 !important;
}
</style>
