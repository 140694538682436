<template>
  <div
    class="carousel-container carrousel rounded shadow border-color-vio border"
    @mouseenter="showNavigation = true"
    @mouseleave="showNavigation = false"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <CarouselItem v-for="(slide, index) in slidesToShow" :key="index">
      <div v-show="currentSlide === index + 1" class="carousel-inner">
        <div class="image-container">
          <img :src="slide" />
        </div>
      </div>
    </CarouselItem>
    <div v-if="navigationEnabled && showNavigation" class="navigate">
      <div class="toggle-page left" @click="prevSlide">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="toggle-page right" @click="nextSlide">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <div v-if="paginationEnabled" class="pagination">
      <span
        @click="goToSlide(index)"
        v-for="(slide, index) in getSlideCount"
        :key="index"
        :class="{ active: index === currentSlide - 1 }"
      ></span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import CarouselItem from "./CarouselItem.vue";

export default {
  components: {
    CarouselItem,
  },
  props: [
    "slides",
    "slides2",
    "showSlides1",
    "startAutoPlay",
    "timeout",
    "navigation",
    "pagination",
  ],
  setup(props) {
    const currentSlide = ref(1);
    const getSlideCount = ref(null);
    const autoPlayEnabled = ref(true);
    const timeoutDuration = ref(7000);
    const paginationEnabled = ref(
      props.pagination === undefined ? true : props.pagination
    );
    const navigationEnabled = ref(
      props.navigation === undefined ? true : props.navigation
    );
    const showNavigation = ref(false);
    let timer = null;

    const nextSlide = () => {
      if (currentSlide.value === getSlideCount.value) {
        currentSlide.value = 1;
      } else {
        currentSlide.value += 1;
      }
      resetTimer();
    };

    const prevSlide = () => {
      if (currentSlide.value === 1) {
        currentSlide.value = getSlideCount.value;
      } else {
        currentSlide.value -= 1;
      }
      resetTimer();
    };

    const goToSlide = (index) => {
      currentSlide.value = index + 1;
      resetTimer();
    };

    const autoPlay = () => {
      if (autoPlayEnabled.value) {
        timer = setInterval(() => {
          nextSlide();
        }, timeoutDuration.value);
      }
    };

    const resetTimer = () => {
      if (timer) {
        clearInterval(timer);
        autoPlay();
      }
    };

    const slidesToShow = ref(props.showSlides1 ? props.slides : props.slides2);

    watch(
      () => props.showSlides1,
      (newStatus) => {
        slidesToShow.value = newStatus ? props.slides : props.slides2;
        currentSlide.value = 1;
        resetTimer();
      }
    );

    onMounted(() => {
      getSlideCount.value = props.slides.length;
      autoPlay();
    });

    return {
      currentSlide,
      getSlideCount,
      nextSlide,
      prevSlide,
      goToSlide,
      autoPlay,
      autoPlayEnabled,
      paginationEnabled,
      navigationEnabled,
      slidesToShow,
      showNavigation,
      resetTimer,
    };
  },

  data() {
    return {
      touchStartX: 0,
      touchMoveX: 0,
      isSliding: false,
    };
  },

  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.isSliding = true;
    },

    handleTouchMove(event) {
      if (this.isSliding) {
        this.touchMoveX = event.touches[0].clientX;
      }
    },

    handleTouchEnd() {
      if (this.isSliding) {
        if (this.touchMoveX - this.touchStartX > 50) {
          this.prevSlide();
        } else if (this.touchStartX - this.touchMoveX > 50) {
          this.nextSlide();
        }
        this.isSliding = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.toggle-page i {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.toggle-page:hover i {
  opacity: 1;
}

@media (max-width: 767px) {
  .toggle-page i {
    display: none;
  }
}

.carousel-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .carousel-inner {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 10px;
    border: none;
    font-size: 0;
  }

  .image-container {
    max-width: 100%;
    max-height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  /* Styles Carousel */
  position: relative;
  margin-bottom: 5%;
  border-radius: 10px;
  overflow: hidden;

  .navigate {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggle-page {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    margin: 22px;
    color: whitesmoke;
    height: 100%;
    cursor: pointer;
  }

  .left {
    justify-content: flex-start;
    height: 100%;
  }

  .right {
    justify-content: flex-end;
    height: 100%;
  }

  .pagination {
    position: absolute;
    bottom: 14px;
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    .pagination {
      bottom: 6px;
      gap: 4px;
    }
  }

  span {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.6);
    opacity: 0.46;
  }

  @media (max-width: 767px) {
    span {
      width: 5px;
      height: 5px;
    }
  }

  .active {
    background-color: #ffffff;
    opacity: 1;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.7s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.slide-enter-to,
.slide-leave-active {
  opacity: 1;
}
</style>
