<template>
  <div class="slide">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
