<template>
    <BlckAdvisor />
</template>

<script>
import BlckAdvisor from '../Mon-Conseiller/BlckAdvisor.vue';

export default {
    components: {
        BlckAdvisor
    }
}
</script>