import { send } from '../builder/multiquery.client';
import { Query } from '../builder/query';
import { getInstance } from '../../../auth/authWrapper';
import { getCurrentCguAndClientLastTermQuery } from '../queries/cgu.queries';
import { getCurrentCguAuthorization } from '../../api/cgu/cgu.authorizations';
import { getClientLastTermAuthorization } from '../../api/client/client.authorizations';

export default function getCurrentCGURequest() {
    const clientId = getInstance().getUserClientId();
    return send({
        queries: [
            new Query(getCurrentCguAndClientLastTermQuery(clientId), 'cgu')
        ],
        authorizations: [
            getCurrentCguAuthorization(),
            getClientLastTermAuthorization()
        ],
        headers: {
            // TODO: remove after merge of #3262
            'X-AUTH-ROLES': 'CLIENT_GET_LAST_CGU'
        }
    });
}
