<template>
    <div class="passReset">
        <div class="popup-backdrop"></div>
        <div class="popup-password popup-wrapper rounded-3 border shadow">
            <div class="row">
                <h4 class="bold-password constituer-dossier-bold-content">{{Title_Label}}</h4>
                <hr />
                <div>
                    <p>{{Intro_Label}}</p>
                    <p>{{Intro_Condition_Label}}</p>
                </div>
            </div>
            <form @submit.prevent="passwordSubmit">
                <div class="form-group">
                    <label class="bold-password" for="password">{{Password_Label}}</label>
                    <input type="password" v-model="pass.password" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && v$.pass.password.$error }" />
                    <div v-if="submitted && v$.pass.password.$error" class="invalid-feedback">
                        <span v-if="v$.pass.password.required.$invalid">{{Password_Required_Label}}<br/></span>
                        <span v-if="v$.pass.password.minLength.$invalid">{{Password_Min_Length_Label}}<br/></span>
                        <span v-if="v$.pass.password.containsUppercase.$invalid">{{Password_Uppercase_Label}}<br/></span>
                        <span v-if="v$.pass.password.containsLowercase.$invalid">{{Password_Lowercase_Label}}<br/></span>
                        <span v-if="v$.pass.password.containsNumber.$invalid">{{Password_Number_Label}}<br/></span>
                        <span v-if="v$.pass.password.containsSpecial.$invalid">{{Password_Special_Label}}<br/></span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="bold-password" for="confirmPassword">{{Password_Confirm_Label}}</label>
                    <input type="password" v-model="pass.confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && v$.pass.confirmPassword.$error }" />
                    <div v-if="submitted && v$.pass.confirmPassword.$error" class="invalid-feedback">
                        <span v-if="v$.pass.confirmPassword.required.$invalid">{{Password_Confirm_Required_Label}}</span>
                        <span v-else-if="v$.pass.confirmPassword.sameAsPassword.$invalid">{{Password_Confirm_Match_Label}}</span>
                    </div>
                </div>
                <div>
                    <br/>
                    <p>{{Info_Deconnexion_Label_1}}</p>
                    <p>{{Info_Deconnexion_Label_2}}</p>
                </div>
                <div class="form-group">
                    <button class="button-warning-cus to-validate-with-frost">{{Valider_label}}
                        <div class="arrow"/>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
    components: {
    },
    setup() {
        return {
            v$: useVuelidate(),
        }
    },
    data() {
        return {
            Intro_Label: 'Pour des raisons de sécurité, veuillez créer un nouveau mot de passe.',
            Intro_Condition_Label: 'Votre mot de passe doit contenir au moins 8 caractères y compris une majuscule, une minuscule, un chiffre et un caractère spécial (!@#$%^&*).',
            Info_Deconnexion_Label_1: 'Suite à la personnalisation de votre mot de passe,',
            Info_Deconnexion_Label_2: 'vous devrez vous reconnecter à votre espace personnel.',
            Title_Label: 'Personnalisez votre mot de passe',
            submitted : true,
            pass: {
                password: "",
                confirmPassword: ""
            },
            Password_Label: 'Nouveau mot de passe',
            Password_Required_Label: 'Mot de passe nécessaire.',
            Password_Min_Length_Label: 'Le mot de passe doit contenir au moins 8 caractères.',
            Password_Uppercase_Label: 'Le mot de passe doit contenir au moins 1 majuscule.',
            Password_Lowercase_Label: 'Le mot de passe doit contenir au moins 1 minuscule.',
            Password_Number_Label: 'Le mot de passe doit contenir au moins 1 chiffre.',
            Password_Special_Label: 'Le mot de passe doit contenir au moins 1 caractère spécial.',
            Password_Confirm_Label: 'Confirmation du nouveau mot de passe.',
            Password_Confirm_Required_Label: 'Confirmation de mot de passe nécessaire.',
            Password_Confirm_Match_Label: 'Les mots de passe doivent correspondre.',
            Valider_label: 'Valider',
        }
    },
    validations: {
        pass: {
            password: {
                required,
                minLength: minLength(8),
                containsUppercase: function(value) {
                    return /[A-Z]/.test(value)
                },
                containsLowercase: function(value) {
                    return /[a-z]/.test(value)
                },
                containsNumber: function(value) {
                    return /[0-9]/.test(value)
                },
                containsSpecial: function(value) {
                    return /[!@#$%^&*]/.test(value)
                }
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    methods: {
        ...mapActions('client', ['changePassword']),
        passwordSubmit () {
            this.submitted = true
            this.v$.$touch()
            if (this.v$.$invalid) {
                return false
            }
            this.doPostPassword().then((isOk) => {
                if (isOk) {
                    this.auth.loginWithRedirect();
                    //this.onClosePopup();
                }
            });
        },
        /**
         * @return Promise<boolean>
         */
        async doPostPassword() {
            return await this.changePassword({
                password: this.pass.password
            }).then((response) => {
                return response;
            });
        },
        onClosePopup () {
            this.$emit('closedPopup')
        },
    }
}
</script>

<style scoped lang="scss">

    .bold-password {
        font-weight: bold;
    }
    .popup-password {
        height: 70% !important;
        width: 70% !important;
        top: 10% !important;
        //overflow: hidden !important;
    }
    @media only screen and (max-width: 600px) {
        .popup-password {
            width: 95% !important;
        }
    }
    .popup-wrapper {
        padding: 8px 16px;
        top: 1vh;
        height: 98vh;
        width: 98vw;
        display: flex;
        flex-direction: column;

        .pdf-container {
            flex-grow: 2;
        }

        label {
            display: inline;
        }
    }
    .popup-content {
        height: 100%;
        width: 98%;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .disabledBtn{
        background-color: grey;
        color: white;
    }
</style>
